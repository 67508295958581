<template>
    <div class="policy-content">
        <div v-html="agreement"></div>
    </div>
</template>

<script>
    export default {
        name: 'policy',
        data() {
            return {
                agreement: ''
            }
        },
        created() {
            this.getPolicyDetailst()
        },
        methods: {
            // 佣金记录
            getPolicyDetailst() {
                this.$getHttp(this.$api.policyDetails).then(res => {
                    this.agreement = res.data.agreement || ''
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.policy-content{
    padding: 20px 20px;
    background: #fff;
    @include font(16px,#333);
    *{
        line-height: 28px;
    }
}
</style>